@import '@/theme/mixin.scss';
.cookie-preference{
    width: 800px;
    background: var( --g-bg-color);
    --var-padding:20px;
    div.head-title{
        color: var( --g-primary-color);
        font-family:  var(--font-bold);
        padding: var( --var-padding);
        font-size: 20px;
    }
    div.diag-content{
        border-top: 1px solid var(--color-border);
        border-bottom: 1px solid var(--color-border);
        padding: var( --var-padding);
        div.nav-swiper{
            width: 100%;
            .swiper-slide{
                width: auto;
                border-bottom: 3px solid transparent;
                cursor: pointer;
                font-size: 14px;
                color: var(--g-text-color);
                &.nav-active{
                    font-family:  var(--font-bold);
                    color: var(--mg-button-background);
                    border-color: var(--mg-button-background);
                }
            }
        }
        div.content-swiper{
            padding: var( --var-padding) 0;
            div.desc{
                font-size: 16px;
                line-height: 1.71;
                color: var(--color-label);
            }
            ul{
                margin-top: var( --var-padding);
                margin-left: 20px;
                li{
                    font-size: 14px;
                    line-height: 1.71;
                    color: var(--color-label);
                    .text {
                        display: flex;
                        gap: 5px;
                        &::before {
                            content: '';
                            flex-shrink: 0;
                            display: block;
                            width: 5px;
                            height: 5px;
                            border-radius: 50%;
                            margin-right: 5px;
                            margin-top: 9px;
                            background: var(--color-label);
                        }

                    }
                }
            }
            .open-switch{
                display: flex;
                align-items: center;
                justify-content: flex-end;
                padding-top: 30px;
                .site-switch{
                    width: 40px;
                    padding: 2px 1px;
                    height: 20px;
                    border-radius: 10px;
                    .site-switch-cursor{
                        width: 16px;
                        height: 16px;
                        box-shadow: 0px 2px 5px 0px #0000001A;
                        border: none;
                        transform: translateY(0px);
                    }
                    &.on{
                        background-color: var(--g-btn-background);
                    }
                }
                &.disabled{
                    .site-switch{
                        cursor: not-allowed;
                        &.on{
                            opacity: 0.85;
                        }
                    }
                    
                }
            }
        }
    }
    div.diag-footer{
        padding: var( --var-padding);
        display: flex;
        justify-content: flex-end;
        align-items: center;
        div.coo-btn{
            margin-left: 20px;
            cursor: pointer;
            padding: 0 40px;
            font-family: var(--font-medium);
            height: 40px;
            line-height: 40px;
            white-space: nowrap;
            &.reject,&.save{
                color: var(--g-btn-background);
                border: 1px solid var(--g-btn-background);
            }
            &.allow{
                color: var(--g-btn-color);
                background: var(--g-btn-background);
            }
        }
    }

    @include media-800{
        width: 100vw;
        --var-padding:15px;
      
        div.diag-content{
            border-bottom: none;
            div.content-swiper{
                .open-switch{
                    justify-content: flex-start;
                }
            }
            
        }
        div.diag-footer{
            flex-wrap: wrap;
            flex-direction: column;
            div.coo-btn{
                flex: 1;
                padding: 0;
                margin: 0;
                text-align: center;
                width: 100%;
                &+div.coo-btn{
                    margin-top: 20px;
                }
            }
        }
    }
}

.cookie-preference-pop.pop{
    z-index: var(--level-high);
    @include media-800{
        &.fadeIn{
            top: auto;
            bottom: 0;
            transform: translate(-50%, 0%);
        }
    }
}
.cookie-preference-pop-mask.pop-mask{
    z-index: calc(var(--level-high) - 1);
}